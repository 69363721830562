import React from "react";
import loadable from "@loadable/component";
import Seo from "../components/Seo";
// import Quote from "../components/Quote"
// import HeroSection from "../components/HeroSection";
// import HomeFeatures from "../components/HomeFeatures";

const HeroSection = loadable(() => import("../components/HeroSection"));
const HomeFeatures = loadable(() => import("../components/HomeFeatures"));

const IndexPage = () => (
  <React.Fragment>
    <Seo manualSlug="" />
    <HeroSection />
    {/* <Quote /> */}
    {/* <hr class="featurette-divider" /> */}
    <HomeFeatures />
  </React.Fragment>
);

export default IndexPage;
